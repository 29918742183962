import { baseApiQuery, providesList } from 'shared/helpers/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { showApiErrorSnackbar } from 'shared/helpers/thunk';
import { stockReceiptActions } from './stockReceipt.state';
import { t } from 'shared/translations';
const api = createApi({
    reducerPath: 'api/receipt/stock',
    tagTypes: ['Invoices', 'StockReceiptItems'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getStockInvoices: builder.query({
            query: (body) => ({
                url: 'stock/invoices',
                method: 'POST',
                body,
            }),
            // transformResponse: (response: StockInvoice[]) =>
            // 	response.map((invoice) => ({
            // 		...invoice,
            // 		invoiceDate: new Date(invoice.invoiceDate),
            // 	})),
            // providesTags: (result) => providesList(result, 'StockInvoices', 'stockInvoiceId'),
        }),
        // getStockReceiptItems: builder.query<StockReceiptItemsResponse, GetStockReceiptItemsRequest>({
        getStockReceiptItems: builder.query({
            query: ({ stockInvoiceId, verification }) => ({
                // TODO: later (when new backend is done) - we actually need only InvoiceInfo fields but got all Invoice fields.
                url: 'stock/receiptItems',
                method: 'POST',
                // Different queries: for Verification tab - only `IsAccepted` and `IsBlocked` flags are valid; for Invoice receiptItems - only stockInvoiceId.
                body: { ...(verification ? { IsAccepted: false, IsBlocked: false } : { stockInvoiceId }) },
            }),
            onQueryStarted: async ({ stockInvoiceId, verification }, { dispatch, queryFulfilled }) => {
                try {
                    const result = await queryFulfilled;
                    // if (stockInvoiceId) dispatch(stockReceiptActions.setVerificationItemCount(result.data.length));
                    if (verification)
                        dispatch(stockReceiptActions.setVerificationItemCount(result.data.length));
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.stock.receipt.items.request.failed'), error }));
                }
            },
            providesTags: (receiptItems) => providesList(receiptItems, 'StockReceiptItems', 'stockReceiptItemId'),
        }),
        // TODO: check if it is used
        getStockCells: builder.query({
            query: () => ({
                method: 'GET',
                url: 'stock/cells',
            }),
        }),
        /** TODO: old backend bug.
         * In case of error the endpoint still returns StatusCode 200 - OK! But result is populated with object:
         * (Example:) {
         * "success": false,
         * "error": "ячейка не принадлежит текущему стоку"
         * }
         */
        placeInStock: builder.mutation({
            // TODO: to return when new backend is done! We do not need `queryFn` here, queryFn only converts OK result with error to BAD result
            // query: (body) => ({
            // 	method: 'POST',
            // 	url: 'stock/place',
            // 	body,
            // }),
            queryFn: async (body, { dispatch }, extraOptions, baseQuery) => {
                const task = baseQuery({ method: 'POST', url: 'stock/place', body });
                const result = await task;
                const apiResult = result.data;
                if (!apiResult.success) {
                    return { error: { status: 500, data: apiResult.error } };
                }
                return { data: apiResult };
            },
            invalidatesTags: (result, error, { stockReceiptItemId }) => error
                ? []
                : [
                    { type: 'StockReceiptItems', id: stockReceiptItemId },
                    // // 'ReceiptItems' invalidation is not to be used here, we manually update items in onQueryStarted with pessimistic update.
                    // // { type: 'ReceiptItems', id: receiptItemId },
                    // { type: 'StickerItems', id: 'LIST' },
                ],
        }),
        // uploadPricesFile: builder.mutation<void, { file: File; stockId: number }>({
        // 	query: ({ file, stockId }) => {
        // 		const formData = new FormData();
        // 		formData.append('file', file);
        // 		formData.append('stockId', JSON.stringify(stockId));
        // 		return {
        // 			method: 'POST',
        // 			url: 'stockDocument/updatePrice',
        // 			body: formData,
        // 			formData: true,
        // 		};
        // 	},
        // 	invalidatesTags: [{ type: 'Stock' }],
        // }),
        uploadInvoice: builder.mutation({
            query: (file) => {
                const formData = new FormData();
                formData.append('file', file);
                return {
                    method: 'POST',
                    url: 'stockInvoiceParsing/upload',
                    body: formData,
                };
            },
        }),
        getParsedInvoiceSettings: builder.query({
            query: ({ stockInvoiceId, verification }) => ({
                url: 'stockInvoiceParsing/settings',
                method: 'POST',
                // cache: 'no-cache'
            }),
            // onQueryStarted: async ({ stockInvoiceId, verification }, { dispatch, queryFulfilled }) => {
            // 	try {
            // 		const result = await queryFulfilled;
            // 		// if (stockInvoiceId) dispatch(stockReceiptActions.setVerificationItemCount(result.data.length));
            // 		if (verification) dispatch(stockReceiptActions.setVerificationItemCount(result.data.length));
            // 	} catch (error) {
            // 		dispatch(showApiErrorSnackbar({ header: t('get.stock.receipt.items.request.failed'), error }));
            // 	}
            // },
            // providesTags: (receiptItems) => providesList(receiptItems, 'StockReceiptItems', 'stockReceiptItemId'),
        }),
    }),
});
export const { useGetStockInvoicesQuery, useGetStockReceiptItemsQuery, usePlaceInStockMutation, useUploadInvoiceMutation, useLazyGetParsedInvoiceSettingsQuery, } = api;
export { api as stockReceiptApi };
