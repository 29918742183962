import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { createAppAsyncThunk } from 'warehouse/helpers';
import { getReceiptItemAvailableQuantity } from 'warehouse/modules/receipt/shared/helpers';
import { playWarehouseSound } from 'warehouse/hooks';
import { receiptApprovementSnackbarDuration } from 'warehouse/modules/receipt/shared/constants';
import { stockReceiptActions } from '../stockReceipt.state';
import { useReceiptItemTableStore } from 'warehouse/modules/receipt/shared/ReceiptItemTable.state';
// import { getConfirmFieldsFromReceiptItem, getReceiptItemAvailableQuantity } from 'warehouse/modules/receipt/stock/helpers';
const tryPlaceStockReceiptItem = createAppAsyncThunk('receipt/tryPlaceStockReceiptItem', async (receiptItem, { getState, dispatch, rejectWithValue }) => {
    if (!receiptItem) {
        // const searchTerm = getState().stockReceipt.sharedArticleLocalSearchTerm;
        const { sharedArticleLocalSearchTerm: searchTerm, setSharedArticleLocalSearchTerm: setSearchTerm } = useReceiptItemTableStore.getState();
        if (searchTerm.trim().length === 0)
            return;
        // A single snackbar is allowed at one moment. Manually closing existed one, since
        closeSnackbar();
        playWarehouseSound('error');
        enqueueSnackbar({
            key: searchTerm,
            preventDuplicate: true,
            variant: 'receiptSubmissionError',
            article: searchTerm,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            autoHideDuration: receiptApprovementSnackbarDuration,
        });
        // dispatch(stockReceiptActions.setSharedArticleLocalSearchTerm(''));
        setSearchTerm('');
        return;
    }
    const availableQuantity = getReceiptItemAvailableQuantity(receiptItem);
    if (availableQuantity < 1) {
        playWarehouseSound('full');
        return;
    }
    // TODO: to delete later
    // We also need quantity and cellName here
    // // StockReceiptItemPlace
    // const receiptItemPlace = getPlaceFieldsFromReceiptItem(receiptItem);
    // // const receiptItemConfirm = getConfirmFieldsFromReceiptItem(receiptItem);
    // if (availableQuantity === 1) {
    // 	const item = {
    // 		...receiptItemPlace,
    // 		quantity: 1,
    // 	};
    // 	dispatch(placeStockReceiptItem(item));
    // 	return;
    // }
    // const { invoiceDate, invoiceNum, price, stockId, stockInvoiceId, ...dialogData } = receiptItem;
    const dialogData = receiptItem;
    // const dialogData: PlaceReceiptItemDialogData = {
    // 	article: receiptItem.article,
    // 	brand: receiptItem.brand,
    // };
    // dispatch(stockReceiptActions.openApproveReceiptItemDialog({ receiptItemConfirm: receiptItemConfirm, availableQuantity }));
    dispatch(stockReceiptActions.openPlaceReceiptItemDialog(dialogData));
});
export { tryPlaceStockReceiptItem };
