import { createSlice } from '@reduxjs/toolkit';
import { endOfDay, startOfDay, subMonths } from 'date-fns';
const initialState = {
    inboxDateRangeFilter: {
        dateFrom: startOfDay(subMonths(new Date(), 6)),
        dateTo: endOfDay(new Date()),
    },
    inboxArticleSearchTerm: '',
    inboxItemCount: undefined,
    verificationItemCount: undefined,
    // currentItemIndex: -1,
    // sharedArticleLocalSearchTerm: '',
    // shouldMoveRowSelectionTo: 0,
    ownerLocation: 'invoice',
    placeStockReceiptItemDialog: {
        open: false,
        data: undefined,
        // availableQuantity: 0,
        // isError: false,
        // error: undefined,
    },
    // approveReceiptItemDialog: {
    // 	open: false,
    // 	data: undefined,
    // 	availableQuantity: 0,
    // 	isError: false,
    // 	error: undefined,
    // },
    // revokeReceiptItemDialog: {
    // 	open: false,
    // 	data: undefined,
    // 	markedStickerItemIds: [],
    // },
    // blockReceiptItemDialog: {
    // 	open: false,
    // 	data: undefined,
    // },
};
const slice = createSlice({
    name: 'stockReceipt',
    initialState,
    reducers: {
        setInboxDateRangeFilter: (state, action) => {
            // // TODO: to delete
            // console.log('inboxDateRangeFilter:', action.payload);
            state.inboxDateRangeFilter = action.payload;
        },
        setInboxItemCount: (state, action) => {
            state.inboxItemCount = action.payload;
        },
        setVerificationItemCount: (state, action) => {
            state.verificationItemCount = action.payload;
        },
        setInboxArticleSearchTerm: (state, action) => {
            state.inboxArticleSearchTerm = action.payload;
        },
        // setCurrentItemIndex: (state, action: PayloadAction<number>) => {
        // 	state.currentItemIndex = action.payload;
        // },
        // setSharedArticleLocalSearchTerm: (state, action: PayloadAction<string>) => {
        // 	state.sharedArticleLocalSearchTerm = action.payload;
        // },
        // setShouldMoveRowSelectionTo: (state, action: PayloadAction<number>) => {
        // 	state.shouldMoveRowSelectionTo = action.payload;
        // },
        setOwnerLocation: (state, action) => {
            state.ownerLocation = action.payload;
        },
        // openPlaceReceiptItemDialog: (state, action: PayloadAction<{ receiptItemPlace: PlaceReceiptItemDialogData; availableQuantity: number }>) => {
        openPlaceReceiptItemDialog: (state, action) => {
            state.placeStockReceiptItemDialog = {
                ...state.placeStockReceiptItemDialog,
                open: true,
                data: {
                    ...action.payload,
                },
                // availableQuantity: action.payload.availableQuantity,
            };
        },
        closePlaceReceiptItemDialog: (state) => {
            state.placeStockReceiptItemDialog = initialState.placeStockReceiptItemDialog;
        },
        // openApproveReceiptItemDialog: (
        // 	state,
        // 	action: PayloadAction<{ receiptItemConfirm: Omit<ReceiptItemApprove, 'quantity'>; availableQuantity: number }>
        // ) => {
        // 	state.approveReceiptItemDialog = {
        // 		...state.approveReceiptItemDialog,
        // 		open: true,
        // 		data: {
        // 			...action.payload.receiptItemConfirm,
        // 			isBlocked: false,
        // 		},
        // 		availableQuantity: action.payload.availableQuantity,
        // 	};
        // },
        // closeApproveReceiptItemDialog: (state) => {
        // 	state.approveReceiptItemDialog = initialState.approveReceiptItemDialog;
        // },
        // openRevokeReceiptItemDialog: (state, action: PayloadAction<RevokeReceiptItemProps>) => {
        // 	state.revokeReceiptItemDialog.open = true;
        // 	state.revokeReceiptItemDialog.data = action.payload;
        // },
        // markStickerItem: (state, action: PayloadAction<{ stickerItemId: number }>) => {
        // 	const markedIds = state.revokeReceiptItemDialog.markedStickerItemIds;
        // 	const id = action.payload.stickerItemId;
        // 	state.revokeReceiptItemDialog.markedStickerItemIds = markedIds.includes(id)
        // 		? markedIds.filter((markedId) => markedId !== id)
        // 		: [...markedIds, id];
        // },
        // markAll: (state, action: PayloadAction<{ stickerItems: ReceiptStickerItems }>) => {
        // 	const markedIds = state.revokeReceiptItemDialog.markedStickerItemIds;
        // 	const revokableStickers = action.payload.stickerItems.filter((stickerItem) => isStickerStateRevokable(stickerItem.state));
        // 	state.revokeReceiptItemDialog.markedStickerItemIds =
        // 		markedIds.length === revokableStickers.length ? [] : revokableStickers.map((sticker) => sticker.stickerItemId);
        // },
        // closeRevokeReceiptItemDialog: (state) => {
        // 	state.revokeReceiptItemDialog = initialState.revokeReceiptItemDialog;
        // },
        // openBlockReceiptItemDialog: (state, action: PayloadAction<ReceiptItem>) => {
        // 	const { receiptItemId, article, brand, invoiceId, name, quantity, receiptedQuantity, blockedQuantity, userName } = action.payload;
        // 	// TODO: (later) Nicer to use function like lodash's pick, or:
        // 	// const picked = (({ receiptItemId, article, brand, invoiceId, name, quantity, userName }) => ({
        // 	// 	receiptItemId,
        // 	// 	article,
        // 	// 	brand,
        // 	// 	invoiceId,
        // 	// 	name,
        // 	// 	quantity,
        // 	// 	userName,
        // 	// }))(action.payload);
        // 	state.blockReceiptItemDialog.open = true;
        // 	state.blockReceiptItemDialog.data = {
        // 		receiptItemId,
        // 		article,
        // 		brand,
        // 		invoiceId,
        // 		name,
        // 		availableQuantity: quantity - receiptedQuantity - blockedQuantity,
        // 		userName,
        // 		isBlocked: true,
        // 	};
        // },
        // closeBlockReceiptItemDialog: (state) => {
        // 	state.blockReceiptItemDialog = initialState.blockReceiptItemDialog;
        // },
    },
    // TODO: to delete if no need
    // extraReducers: (builder) => {
    // 	builder.addCase(confirmReceiptItem.rejected, (state, action) => {
    // 		// state.approveReceiptItemDialog.isError = true;
    // 		// state.approveReceiptItemDialog.error = action.error;
    // 	});
    // },
});
export const { actions: stockReceiptActions, reducer: stockReceiptStateReducer } = slice;
