import { baseApiQuery, providesList } from 'shared/helpers/api';
import { showApiErrorSnackbar, showApiSuccessSnackbar } from 'shared/helpers/thunk';
import { createApi } from '@reduxjs/toolkit/query/react';
import { redirect } from 'react-router-dom';
import { t } from 'shared/translations';
export const api = createApi({
    reducerPath: 'api/packing',
    tagTypes: ['Packing', 'PackingDetails', 'DeliveryBoxes'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getPackingItems: builder.query({
            query: () => ({
                method: 'GET',
                url: 'picking/summaryInfo',
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    const result = await queryFulfilled;
                    // dispatch(placementActions.setReceiptedItemCount(result.data?.length ?? 0));
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.packing.items.request.failed'), error }));
                }
            },
            providesTags: (result) => providesList(result, 'Packing', 'userId'),
        }),
        getCustomersForPacking: builder.query({
            query: () => ({
                method: 'GET',
                url: 'picking/users4picking',
                cache: 'no-cache',
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    // dispatch(placementActions.setPlacedItemCount(result.data?.length ?? 0));
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.customers.for.packing.request.failed'), error }));
                }
            },
            // providesTags: (result) => [{ type: 'Cells', id: 'LIST' }],
        }),
        startPacking: builder.mutation({
            query: ({ customerIds }) => ({
                method: 'POST',
                url: 'picking/startPicking4Users',
                body: { userIds: customerIds },
            }),
            onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (!data.success)
                        dispatch(showApiErrorSnackbar({ header: t('start.packing.for.users.request.failed'), error: 'Unknown error' }));
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('start.packing.for.users.request.failed'), error }));
                }
            },
            // invalidatesTags: (result, error, { stickerId }) => (error ? [] : [{ type: 'Placement', id: stickerId }, { type: 'Cells' }]),
        }),
        getPackingDetails: builder.query({
            query: ({ packingType, customerId, legalId }) => ({
                method: 'GET',
                // TODO: to delete this comment later
                // url: 'picking/detailedInfo?pickingType=1&userId=560&legalId=436',
                url: 'picking/detailedInfo',
                cache: 'no-cache',
                params: { pickingType: packingType, userId: customerId, legalId },
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    // dispatch(placementActions.setPlacedItemCount(result.data?.length ?? 0));
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.packing.details.request.failed'), error }));
                }
            },
            // providesTags: (result) => [{ type: 'PackingDetails', id: 'LIST' }],
            providesTags: (result) => [{ type: 'PackingDetails', id: 'LIST' }],
        }),
        assembleSticker: builder.mutation({
            query: ({ stickerId, deliveryBoxId }) => ({
                method: 'POST',
                url: `/picking/pickup/${stickerId}`,
                params: { deliveryBoxId },
            }),
            onQueryStarted: async (_, { queryFulfilled, dispatch, getState }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (!data.success) {
                        dispatch(showApiErrorSnackbar({ header: t('pack.detail.sticker.request.failed'), error: 'Unknown error' }));
                        return;
                    }
                    // const patchResult = dispatch(
                    // 	api.util.updateQueryData('getReceiptItems', receiptItemsRequestPayload, (draft) => {
                    // 		// TODO: to delete
                    // 		// console.log('draft:', current(draft));
                    // 		const patch: ReceiptItemsResponse = {
                    // 			...draft,
                    // 			// Data from invoice is used in Invoice progress, so it should also be updated.
                    // 			invoice: {
                    // 				...draft.invoice,
                    // 				...(isBlocked
                    // 					? { blockedQuantity: draft.invoice.blockedQuantity + quantity }
                    // 					: { receiptedQuantity: draft.invoice.receiptedQuantity + quantity }),
                    // 			},
                    // 			receiptItems: draft.receiptItems.map((receiptItem) =>
                    // 				receiptItem.receiptItemId === receiptItemId
                    // 					? {
                    // 							...receiptItem,
                    // 							...(isBlocked
                    // 								? { blockedQuantity: receiptItem.blockedQuantity + quantity }
                    // 								: { receiptedQuantity: receiptItem.receiptedQuantity + quantity }),
                    // 					  }
                    // 					: receiptItem
                    // 			),
                    // 		};
                    // 		Object.assign(draft, patch);
                    // 	})
                    // );
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('pack.detail.sticker.request.failed'), error }));
                }
            },
            //  invalidatesTags: (result, error, { stickerId }) => (error ? [] : [{ type: 'Placement', id: stickerId }, { type: 'Cells' }]),
            invalidatesTags: (result, error, { stickerId }) => (error ? [] : [{ type: 'PackingDetails', id: stickerId }, { type: 'DeliveryBoxes' }]),
        }),
        removeAwaySticker: builder.mutation({
            query: ({ stickerId }) => ({
                method: 'POST',
                url: `picking/putout/${stickerId}`,
            }),
            onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (!data.success)
                        dispatch(showApiErrorSnackbar({ header: t('unpack.detail.sticker.request.failed'), error: 'Unknown error' }));
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('unpack.detail.sticker.request.failed'), error }));
                }
            },
            // invalidatesTags: (result, error, { stickerId }) => (error ? [] : [{ type: 'Placement', id: stickerId }, { type: 'Cells' }]),
        }),
        saveDeliveryBox: builder.mutation({
            query: ({ packingType, customerId, legalId }) => ({
                method: 'POST',
                url: 'picking/saveDeliveryBox',
                body: { pickingType: packingType, userId: customerId, legalId },
            }),
            onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
                try {
                    await queryFulfilled;
                    // `result` is returned via socket.
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('save.delivery.box.request.failed'), error }));
                }
            },
            // invalidatesTags: (result, error, { stickerId }) => (error ? [] : [{ type: 'Placement', id: stickerId }, { type: 'Cells' }]),
        }),
        deleteDeliveryBox: builder.mutation({
            query: ({ boxId }) => ({
                method: 'POST',
                url: 'picking/deleteDeliveryBox',
                params: { boxId },
            }),
            onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('delete.delivery.box.request.failed'), error }));
                }
            },
            // invalidatesTags: (result, error, { stickerId }) => (error ? [] : [{ type: 'Placement', id: stickerId }, { type: 'Cells' }]),
        }),
        createRealization: builder.mutation({
            query: ({ packingType, customerId, legalId }) => ({
                method: 'POST',
                url: 'picking/realization',
                body: { pickingType: packingType, userId: customerId, legalId },
            }),
            onQueryStarted: async (_, { queryFulfilled, dispatch, getState }) => {
                try {
                    await queryFulfilled;
                    const { progressPercent } = getState().packing.packingDetails;
                    if (progressPercent === 100) {
                        redirect('/packing');
                        dispatch(showApiSuccessSnackbar(t('realization.created.successfully')));
                    }
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('create.realization.request.failed'), error }));
                }
            },
            // invalidatesTags: (result, error, { stickerId }) => (error ? [] : [{ type: 'Placement', id: stickerId }, { type: 'Cells' }]),
        }),
    }),
});
export const { useGetPackingItemsQuery, useGetCustomersForPackingQuery, useStartPackingMutation, useGetPackingDetailsQuery, useAssembleStickerMutation, useRemoveAwayStickerMutation, useCreateRealizationMutation, } = api;
export { api as packingApi };
